/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, createContext, useEffect } from "react";

const authContext = createContext();

export function getAuthToken() {
  return localStorage.getItem("authToken");
}

function useProvideAuth() {
  const backendUrl = "https://api.studenthub.club";
  const [user, setUser] = useState();

  const getUser = async () => {
    const authToken = getAuthToken();
    if (!authToken) {
      setUser(null);
      return;
    }
    try {
      const resp = await fetch(`${backendUrl}/users/me`, {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
      if (!resp.ok) {
        console.error(await resp.json());
        setUser(null);
      } else {
        setUser(await resp.json());
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const login = async (username, password) => {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("grant_type", "");
    formData.append("scope", "");
    formData.append("client_id", "");
    formData.append("client_secret", "");
    const loginResponse = await fetch(`${backendUrl}/oauth2/access-token`, {
      method: "POST",
      body: formData,
    });

    if (!loginResponse.ok) {
      const err = await loginResponse.json();
      console.error(err);
      return false;
    }

    const tokens = await loginResponse.json();
    localStorage.setItem("authToken", tokens.access_token);
    getUser(setUser);
    return true;
  };

  const register = (email, password) => {
    //   return promise
  };

  const signout = () => {
    localStorage.removeItem("authToken");
    setUser(null);
  };

  const sendPasswordResetEmail = (email) => {
    // return true;
  };

  //   const confirmPasswordReset = (code, password) => {
  //     return firebase
  //       .auth()
  //       .confirmPasswordReset(code, password)
  //       .then(() => {
  //         return true;
  //       });
  //   };

  //   useEffect(() => {
  //     const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
  //       if (user) {
  //         setUser(user);
  //       } else {
  //         setUser(false);
  //       }
  //     });
  //     // Cleanup subscription on unmount
  //     return () => unsubscribe();
  //   }, []);

  // Return the user object and auth methods
  return {
    user,
    login,
    register,
    signout,
    sendPasswordResetEmail,
    getAuthToken,
    // confirmPasswordReset,
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};
