import * as React from 'react';
import GlobalStyles from "@mui/material/GlobalStyles";

const styles =   {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        WebkitFontSmoothing: "antialiased",
        MozOsxFontSmoothing: "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        backgroundColor: "#f4f6f8",
        height: "100%",
        width: "100%",
      },
      a: {
        textDecoration: "none",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
    }


export default function GlobalCssOverride() {
  return (
    <React.Fragment>
      <GlobalStyles styles={styles} />
      <h1>Grey h1 element</h1>
    </React.Fragment>
  );
}