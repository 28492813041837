import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoOutlinedIcon  from "@mui/icons-material/InfoOutlined";
import { DateTime } from "luxon";

export default function BotDetailCard() {
  return (
    <Card sx={{ maxWidth: 400, margin: "auto" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          transtutors_1
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Email: user@xyz.com
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Expires: {DateTime.now().toISODate()}
        </Typography>
      </CardContent>
      <CardActions>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <IconButton sx={{ minWidth: "auto" }} aria-label="start">
              <PlayArrowIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton aria-label="stop" sx={{ minWidth: "auto" }}>
              <StopIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton sx={{ minWidth: "auto" }} aria-label="edit">
              <EditOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton aria-label="delete" sx={{ minWidth: "auto" }}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton aria-label="delete" sx={{ minWidth: "auto" }}>
              <InfoOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
