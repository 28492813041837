import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ApiTest from "./ApiTest";
import AddIcon from "@mui/icons-material/Add";
import { Link as RouterLink } from "react-router-dom";

const BotToolbar = ({ endpoint, addBotPath, disabled }) => (
  <>
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Button>Import</Button>
      <Button sx={{ mx: 1 }}>Export</Button>
      <Button
        variant="contained"
        component={RouterLink}
        to={addBotPath || "/404"}
        startIcon={<AddIcon />}
        disabled={disabled}
      >
        Add New Bot
      </Button>
    </Box>
    <Box sx={{ mt: 3 }}>
      <ApiTest endpoint={endpoint} />
    </Box>
  </>
);

export default BotToolbar;
