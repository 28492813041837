/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  User as UserIcon,
  Users as UsersIcon,
  Activity as ActivityIcon,
} from "react-feather";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import NavItem from "./NavItem";

const user = {
  avatar: "/static/images/avatars/avatar_5.png",
  jobTitle: "Senior Developer",
  name: "Raven Redstain",
};

const generalItems = [
  {
    href: "/app/dashboard",
    icon: BarChartIcon,
    title: "Dashboard",
  },
  {
    href: "/app/users",
    icon: UsersIcon,
    title: "Users",
  },
  {
    href: "/app/analytics",
    icon: ActivityIcon,
    title: "Analytics",
  },
];

const botManagmentItems = [
  {
    href: "/app/bb-essay",
    icon: SettingsIcon,
    title: "Bartleby Essay",
  },
  {
    href: "/app/bb-learn",
    icon: SettingsIcon,
    title: "Bartleby Learn",
  },
  {
    href: "/app/chegg",
    icon: SettingsIcon,
    title: "Chegg",
  },
  {
    href: "/app/coursehero",
    icon: SettingsIcon,
    title: "Coursehero",
  },
  {
    href: "/app/studocu",
    icon: SettingsIcon,
    title: "StuDocu",
  },
  {
    href: "/app/transtutors",
    icon: SettingsIcon,
    title: "Transtutors",
  },
];

const accountManagmentItems = [
  {
    href: "/app/account",
    icon: UserIcon,
    title: "Account",
  },
  {
    href: "/app/settings",
    icon: SettingsIcon,
    title: "Preferences",
  },
];

function DashboardSidebar({ open, onNavClose }) {
  const location = useLocation();
  useEffect(() => {
    if (open && onNavClose) {
      onNavClose();
    }
  }, [location.pathname]);

  const content = (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          p: 2,
        }}
      >
        <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: "pointer",
            width: 64,
            height: 64,
          }}
          to="/app/account"
        />
        <Typography color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />

      <Box sx={{ p: 2 }}>
        <List>
          <Typography
            color="textPrimary"
            variant="body1"
            sx={{ p: 1.25, fontWeight: "500" }}
          >
            General
          </Typography>
          {generalItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
          <Typography
            color="textPrimary"
            variant="body1"
            sx={{ p: 1.25, fontWeight: "500" }}
          >
            Bot Management
          </Typography>
          {botManagmentItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
          <Typography
            color="textPrimary"
            variant="body1"
            sx={{ p: 1.25, fontWeight: "500" }}
          >
            Settings
          </Typography>
          {accountManagmentItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onNavClose}
          open={open}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: "calc(100% - 64px)",
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
