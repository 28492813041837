import { getAuthToken } from "./use-auth";

async function authFetch(endpoint, method, data) {
  let headers = new Headers();
  headers.append("Authorization", `Bearer ${getAuthToken()}`);
  headers.append("Accept", "application/json");
  if (data) {
    headers.append("Content-Type", "application/json");
  }
  return await fetch(endpoint, {
    method,
    headers,
    body: data ? JSON.stringify(data) : null,
  });
}

export async function getBots(endpoint) {
  return await authFetch(endpoint, "GET");
}

export async function addBot(endpoint, data) {
  return await authFetch(endpoint, "POST", data);
}

export async function updateBot(endpoint, data) {
  return await authFetch(endpoint, "PUT", data);
}

export async function deleteBot(endpoint) {
  return await authFetch(endpoint, "DELETE");
}

export async function startBot(endpoint) {
  return await authFetch(endpoint, "GET");
}

export async function stopBot(endpoint) {
  return await authFetch(endpoint, "GET");
}

export function removeEmptyFields(data) {
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof Object && !(data[key] instanceof Date)) {
      Object.keys(data[key]).forEach((key2) => {
        if (data[key][key2] === "" || data[key][key2] == null) {
          delete data[key][key2];
        }
      });
      if (Object.keys(data[key]).length === 0) {
        delete data[key];
      }
    } else if (data[key] === "" || data[key] == null) {
      delete data[key];
    }
  });

  if (isNaN(data.expires_at)) {
    delete data.expires_at;
  }
  return data;
}
