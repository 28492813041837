import "react-perfect-scrollbar/dist/css/styles.css";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import GlobalCssOverride from "./components/GlobalCssOverride";
import theme from "./theme";
import routes from "./routes";
import { ProvideAuth } from "./utils/use-auth.js";
import Fade from "@mui/material/Fade";
// import { useAuth } from "./utils/use-auth.js";
import { SnackbarProvider } from "notistack";
import { createRef } from "react";
import Button from "@mui/material/Button";

const notistackRef = createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const App = () => {
  const routing = useRoutes(routes);
  // const auth = useAuth();
  // console.log(auth)
  return (
    <ThemeProvider theme={theme}>
      <GlobalCssOverride />
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        TransitionComponent={Fade}
        ref={notistackRef}
        action={(key) => (
          <Button sx={{color:"#ffffff"}} onClick={onClickDismiss(key)}>'Dismiss'</Button>
        )}
      >
        <ProvideAuth>{routing}</ProvideAuth>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
