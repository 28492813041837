import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/material";
import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useAuth } from "../utils/use-auth";
import UserListResults from "../components/users/user-list-result";
import UserListToolbar from "../components/users/user-list-toolbar";
import AddUserForm from "../components/users/add-user-form";
import customers from "../__mocks__/customers";

const UserList = () => {
  const [users, setUsers] = useState({});
  const auth = useAuth();

  useEffect(() => {
    async function getUsers() {
      const resp = await fetch(
        "https://api.studenthub.club/users/?skip=0&limit=100",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${auth.getAuthToken()}`,
            Accept: "application/json",
          },
        }
      );
      const data = await resp.json();
      setUsers(data);
    }
    getUsers();
  }, [auth]);
  console.log(users);
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <UserListToolbar />
        <Box sx={{ pt: 3 }}>
          <UserListResults users={customers} />
        </Box>
      </Container>
    </Box>
  );
};

const Users = () => {
  // const match = useLocation();
  // console.log(match);
  return (
    <>
      <Helmet>
        <title>Users | StudentHub</title>
      </Helmet>
      <Routes>
        <Route exact path="">
          <UserList />
        </Route>
        <Route exact path="add">
          <AddUserForm />
        </Route>
      </Routes>
    </>
  );
};
export default Users;
