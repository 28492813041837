import { Navigate } from "react-router-dom";
import DashboardLayout from "./components/DashboardLayout";
import MainLayout from "./components/MainLayout";
import Account from "./pages/Account";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Analytics from "./pages/Analytics";
import UserList from "./pages/Users";
import Register from "./pages/Register";
import Settings from './pages/Settings';
import Coursehero from "./pages/Coursehero";
import Chegg from "./pages/Chegg";
import Studocu from "./pages/Studocu";
import BartlebyEssay from "./pages/BartlebyEssay";
import BartlebyLearn from "./pages/BartlebyLearn";
import Transtutors from "./pages/Transtutors";
import { useAuth } from "./utils/use-auth";

function Protected({ page: Component}) {
  const auth = useAuth();
  // console.log("account", auth);
  return auth.user ? <Component /> : <Navigate to="/login"/>;
  // return <Component />
}

const routes = [
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      { path: "account", element: <Protected page={Account} /> },
      { path: "users/*", element: <Protected page={UserList} /> },
      { path: 'analytics', element: <Analytics /> },
      { path: "dashboard", element: <Protected page={Dashboard} /> },
      { path: 'settings', element: <Protected page={Settings} /> },
      { path: 'chegg/*', element: <Protected page={Chegg} /> },
      { path: 'coursehero/*', element: <Protected page={Coursehero} /> },
      { path: 'studocu', element: <Protected page={Studocu} /> },
      { path: 'bb-learn', element: <Protected page={BartlebyLearn} /> },
      { path: 'bb-essay', element: <Protected page={BartlebyEssay} /> },
      { path: 'transtutors', element: <Protected page={Transtutors} /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "login", element: <Login /> },
      { path: "register", element: <Register /> },
      { path: "/", element: <Navigate to="/app/dashboard" /> },
      { path: "*", element: <NotFound /> },
    ],
  },
];

export default routes;
