import {
  Grid,
  TextField,
  Button,
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useForm } from "react-hook-form";
import { removeEmptyFields } from "../../utils/bot-utils";
import { useNavigate, useParams } from "react-router-dom";

function CourseheroAddBotForm({ submitCallback }) {
  const navigate = useNavigate();
  const { register, handleSubmit, setValue } = useForm();
  const { botName } = useParams();
  if (botName) setValue("bot_name", botName);

  const onSubmit = async (data) => {
    console.log(data);
    const resp = botName
      ? await submitCallback(botName, removeEmptyFields(data))
      : await submitCallback(removeEmptyFields(data));
    if (resp.ok) {
      navigate("/app/coursehero", { replace: true });
    } else {
      const data = await resp.json();
      console.error(data);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 3, ml: 0 }}>
      <Card>
        <CardHeader
          title={`${Boolean(botName) ? "Update" : "Add a new"} coursehero bot`}
        />
        <Divider />
        <CardContent>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} rowGap={1.5}>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  General
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Bot Name"
                  variant="filled"
                  required
                  type="text"
                  InputProps={{
                    readOnly: Boolean(botName),
                  }}
                  {...register("bot_name", { required: true, maxLength: 20 })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Account Email"
                  variant="filled"
                  type="email"
                  {...register("account_email", {
                    maxLength: 40,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Expires At"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  {...register("expires_at", {
                    valueAsDate: true,
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  Cookies
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Cookies"
                  variant="filled"
                  required={!Boolean(botName)}
                  type="text"
                  {...register("cookies")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  Headers
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Authorization Header"
                  variant="filled"
                  required={!Boolean(botName)}
                  type="text"
                  {...register("headers.authorization")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="User-Agent Header"
                  variant="filled"
                  required={!Boolean(botName)}
                  type="text"
                  {...register("headers.user-agent")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Ch-Device-Id Header"
                  variant="filled"
                  required={!Boolean(botName)}
                  type="text"
                  {...register("headers.ch-device-id")}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
}

export default CourseheroAddBotForm;
