import { Helmet } from "react-helmet";
import { Box, Container, Typography } from "@mui/material";

const Analytics = () => (
  <>
    <Helmet>
      <title>Analytics | StudenHub</title>
    </Helmet>

    <Box
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="md">
        <Typography align="center" color="textPrimary" variant="h1">
          Comming Soon!
        </Typography>
      </Container>
    </Box>
  </>
);

export default Analytics;
