import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/material";
import BotToolbar from "../components/BotListToolbar"

const Bartleby = () => (
  <>
    <Helmet>
      <title>Bartleby | StudenHub</title>
    </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <BotToolbar endpoint="/bartleby/api/learn" />
          {/* <Box sx={{ pt: 3 }}>
            <CourseheroBotsList
              bots={courseheroBots}
              stopBot={stopBot}
              startBot={startBot}
              deleteBot={deleteBot}
              updateBot={updateBot}
            />
          </Box> */}
        </Container>
      </Box>
  </>
);

export default Bartleby;
