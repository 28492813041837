import {
  Grid,
  TextField,
  Button,
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { useState } from "react";


const AddUserForm = ({ handleSubmit }) => {
  const [values, setValues] = useState({
    fullName: "",
    email: "",
  });
  const handleChange = (event) => {
    console.log("change");
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        py: 3,
        ml: 0,
      }}
    >
      <Card>
        <CardHeader subheader="Add details for a new user" title="Create a new user" />
        <Divider />
        <CardContent>
          <form autoComplete="off" noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Full name"
                  name="fullName"
                  onChange={handleChange}
                  required
                  value={values.fullName}
                  variant="outlined"
                />
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  required
                  value={values.email}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={3}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon/>}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default AddUserForm;
