const getInitials = (name = '') => name
  .replace(/\s+/, ' ')
  .split(' ')
  .slice(0, 2)
  .map((v) => v && v[0].toUpperCase())
  .join('');


export const getInitials2 = (name = '') => name
  .replace(/\s+/, ' ')
  .split('_')
  .slice(0, 2)
  .map((v) => v && v[0].toUpperCase())
  .join('');


 
export default getInitials;