import {
  Grid,
  TextField,
  Button,
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { removeEmptyFields } from "../../utils/bot-utils";
// import { styled } from "@mui/material/styles";

// const TextField = styled(MuiTextField)(({ theme }) => ({
//   marginLeft: 3,
//   color: "red",
//   borderRadius: '14px',
//   "& input": {
//     color: "red",
//   }
// }));

function CheggForm({ submitCallback }) {
  const navigate = useNavigate();
  const { register, handleSubmit, setValue } = useForm();
  const { botName } = useParams();
  if (botName) setValue("bot_name", botName);

  const onSubmit = async (data) => {
    const resp = botName
      ? await submitCallback(botName, removeEmptyFields(data))
      : await submitCallback(removeEmptyFields(data));
    if (resp.ok) {
      navigate("/app/chegg", { replace: true });
    } else {
      const data = await resp.json();
      console.error(data);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 3, ml: 0 }}>
      <Card>
        <CardHeader
          title={`${Boolean(botName) ? "Update" : "Add a new"} chegg bot`}
        />
        <Divider />
        <CardContent sx={{ py: 3 }}>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} rowGap={1.5}>
              <Grid item xs="auto">
                <Typography variant="h4" gutterBottom>
                  General
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Bot Name"
                  variant="filled"
                  required
                  type="text"
                  InputProps={{
                    readOnly: Boolean(botName),
                  }}
                  {...register("bot_name", { required: true, maxLength: 20 })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Account Email"
                  variant="filled"
                  type="email"
                  {...register("account_email", {
                    maxLength: 40,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Expires At"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  {...register("expires_at", {
                    valueAsDate: true,
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  Tokens
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Issued At"
                  variant="filled"
                  type="number"
                  required={!Boolean(botName)}
                  {...register("tokens.issued_at", { min: 0 })}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  label="Access Token"
                  variant="filled"
                  required={!Boolean(botName)}
                  {...register("tokens.access_token")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Refresh Token"
                  variant="filled"
                  required={!Boolean(botName)}
                  {...register("tokens.refresh_token")}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  Headers
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="X-Chegg Deviceid"
                  variant="filled"
                  required={!Boolean(botName)}
                  type="text"
                  {...register("headers.x-chegg-deviceid")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="User Agent"
                  variant="filled"
                  required={!Boolean(botName)}
                  type="text"
                  {...register("headers.user-agent")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="X-Chegg Sessionid"
                  variant="filled"
                  required={!Boolean(botName)}
                  type="text"
                  {...register("headers.x-chegg-sessionid")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="x-Chegg UIID"
                  variant="filled"
                  required={!Boolean(botName)}
                  type="text"
                  {...register("headers.x-chegg-uuid")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="X-px Original Token"
                  variant="filled"
                  required={!Boolean(botName)}
                  type="text"
                  {...register("headers.x-px-original-token")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="X-Chegg DFID"
                  variant="filled"
                  required={!Boolean(botName)}
                  type="text"
                  {...register("headers.x-chegg-dfid")}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
}

export default CheggForm;
