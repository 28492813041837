import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import { Formik } from "formik";
import history from 'history/browser';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  Avatar,
  Alert,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useAuth } from "../utils/use-auth";
import { useState } from "react";

const Login = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  if (auth.user) {
    history.back();
    // navigate("/app/dashboard", { replace: true });
  }
  const [showError, setShowError] = useState(false);
  const handleSubmit = async (values) => {
    const isSuccess = await auth.login(values["email"], values["password"]);
    if (isSuccess) {
      navigate("/app/dashboard", { replace: true });
    } else {
      setShowError(true);
      setTimeout(() => setShowError(false), 4000);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | StudentHub</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          height: "100%",
          paddingTop: "64px",
        }}
      >
        <Container maxWidth="xs">
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Avatar sx={{ m: 1 }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography color="textPrimary" component="h1" variant="h3">
                Sign in
              </Typography>
            </Box>
          </Box>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address*"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password*"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                {showError ? (
                  <Alert sx={{ py: 2 }} severity="error">
                    <strong>Login Failed</strong> - "Password or email is
                    incorrect"
                  </Alert>
                ) : (
                  ""
                )}
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs>
                    <Link component={RouterLink} to="#" variant="h6">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" variant="body1">
                      Don't have an account?{" "}
                      <Link component={RouterLink} to="/register" variant="h6">
                        Sign up
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
