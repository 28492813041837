import { useState } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import AlertTitle from "@mui/material/AlertTitle";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";

function ApiTest({endpoint}) {
  const apiUrl = `https://bot.studenthub.club${endpoint}`
  const [requestedUrl, setRequestedUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  function handleChange(e) {
    if (e.target.name === "requestedUrl") setRequestedUrl(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (requestedUrl === "") return;
    (async () => {
      try {
        setLoading(true);
        const payload = {
          token: "39509fsakhjasf3dsaf09o012497sadf",
          requestedUrl
        };
        const resp = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(payload),
        });
        setApiResponse({
          ok: resp.ok,
          status: resp.status,
          json: await resp.json(),
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }

  return (
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={9}>
              <TextField
                fullWidth
                label="Test API"
                name="requestedUrl"
                placeholder="Input a valid coursehero document or qna url"
                variant="outlined"
                value={requestedUrl}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sx={{ position: "relative" }}>
              <Button
                disabled={loading}
                type="submit"
                size="large"
                variant="contained"
              >
                Submit
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              )}
            </Grid>
          </Grid>
        </form>
        {apiResponse && (
          <Alert
            severity={apiResponse.ok ? "success" : "error"}
            sx={{ my: 2, whiteSpace: "pre" }}
          >
            <AlertTitle
              sx={{
                display: "flex",
                minWidth: "370px",
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              {apiResponse.ok ? "Successfully Unlocked" : "Error"}
              <IconButton
                onClick={() => setApiResponse(null)}
                aria-label="close"
                sx={{ p: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </AlertTitle>
            {apiResponse.ok ? (
              <Link
                href={apiResponse.json.unlockedUrl}
                variant="body1"
                target="_blank"
              >
                {apiResponse.json.unlockedUrl}
              </Link>
            ) : (
              JSON.stringify(apiResponse.json, null, "\t")
            )}
          </Alert>
        )}
      </CardContent>
    </Card>
  );
}

export default ApiTest;
