import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/material";
import BotToolbar from "../components/BotListToolbar"
import BotDetailCard from "../components/BotDetailCard";

const Transtutors = () => (
  <>
    <Helmet>
      <title>Transtutors | StudenHub</title>
    </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <BotToolbar endpoint="/transtutors/api" />
          <Box sx={{ pt: 3 }}>
            <BotDetailCard/>
          </Box>
        </Container>
      </Box>
  </>
);

export default Transtutors;