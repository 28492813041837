/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CourseHeroForm from "../components/coursehero/CourseheroForm";
import BotListToolbar from "../components/BotListToolbar";
import BotListResult from "../components/BotListResult";
import { Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  getBots,
  addBot,
  updateBot,
  deleteBot,
  startBot,
  stopBot,
} from "../utils/bot-utils";

const cheroBaseUrl = "https://api.studenthub.club/coursehero";

const CourseheroHome = () => {
  const [CheroBots, setCheroBots] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  async function getCheroBots() {
    const resp = await getBots(`${cheroBaseUrl}/admin/bots`);
    const data = await resp.json();
    if (resp.ok) {
      setCheroBots(data);
    } else {
      console.error(data);
    }
  }

  async function deleteCheroBot(botName) {
    const resp = await deleteBot(`${cheroBaseUrl}/admin/bots/${botName}`);
    const data = await resp.json();
    if (resp.ok) {
      enqueueSnackbar(data.msg, { variant: "success" });
      getCheroBots();
    } else {
      enqueueSnackbar(data.error, { variant: "error" });
    }
  }

  async function startCheroBot(botName) {
    const resp = await startBot(`${cheroBaseUrl}/admin/start-bot/${botName}`);
    const data = await resp.json();
    if (resp.ok) {
      enqueueSnackbar(data.msg, { variant: "success" });
      getCheroBots();
    } else {
      enqueueSnackbar(data.error, { variant: "error" });
    }
  }

  async function stopCheroBot(botName) {
    const resp = await stopBot(`${cheroBaseUrl}/admin/stop-bot/${botName}`);
    const data = await resp.json();
    if (resp.ok) {
      enqueueSnackbar(data.msg, { variant: "success" });
      getCheroBots();
    } else {
      enqueueSnackbar(data.detail, { variant: "error" });
    }
  }

  useEffect(() => getCheroBots(), []);

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <BotListToolbar endpoint="/coursehero/api" addBotPath="add" />
        <Box sx={{ pt: 3 }}>
          <BotListResult
            bots={CheroBots}
            startBot={startCheroBot}
            stopBot={stopCheroBot}
            deleteBot={deleteCheroBot}
            extras={[{ label: "Unlocks Left", key: "unlocks" }]}
          />
        </Box>
      </Container>
    </Box>
  );
};

function Coursehero() {
  return (
    <>
      <Helmet>
        <title>Coursehero | StudenHub</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<CourseheroHome />} />

        <Route
          path="add"
          element={
            <CourseHeroForm
              submitCallback={(data) =>
                addBot(`${cheroBaseUrl}/admin/bots`, data)
              }
            />
          }
        />

        <Route
          path="update/:botName"
          element={
            <CourseHeroForm
              submitCallback={(botName, data) =>
                updateBot(`${cheroBaseUrl}/admin/bots/${botName}`, data)
              }
            />
          }
        />
      </Routes>
    </>
  );
}

export default Coursehero;
