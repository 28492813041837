import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/material";
import BotListToolbar from "../components/BotListToolbar";
import BotListResult from "../components/BotListResult";
import { Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import CheggForm from "../components/chegg/CheggForm";
import {
  getBots,
  stopBot,
  startBot,
  deleteBot,
  addBot,
  updateBot,
} from "../utils/bot-utils";
import { useSnackbar } from "notistack";

const cheggBaseUrl = "https://api.studenthub.club/chegg";
const CheggHome = () => {
  const [cheggBots, setCheggBots] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  async function getCheggBots() {
    const resp = await getBots(`${cheggBaseUrl}/admin/bots`);
    const data = await resp.json();
    if (resp.ok) {
      setCheggBots(data);
    } else {
      console.error(data);
    }
  }

  async function deleteCheggBot(botName) {
    const resp = await deleteBot(`${cheggBaseUrl}/admin/bots/${botName}`);
    const data = await resp.json();
    if (resp.ok) {
      enqueueSnackbar(data.msg, { variant: "success" });
      getCheggBots();
    } else {
      enqueueSnackbar(data.error, { variant: "error" });
    }
  }

  async function startCheggBot(botName) {
    const resp = await startBot(`${cheggBaseUrl}/admin/start-bot/${botName}`);
    const data = await resp.json();
    if (resp.ok) {
      enqueueSnackbar(data.msg, { variant: "success" });
      getCheggBots();
    } else {
      enqueueSnackbar(data.error, { variant: "error" });
    }
  }

  async function stopCheggBot(botName) {
    const resp = await stopBot(`${cheggBaseUrl}/admin/stop-bot/${botName}`);
    const data = await resp.json();
    if (resp.ok) {
      enqueueSnackbar(data.msg, { variant: "success" });
      getCheggBots();
    } else {
      enqueueSnackbar(data.detail, { variant: "error" });
    }
  }

  useEffect(() => getCheggBots(), []);

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <BotListToolbar endpoint="/chegg/api" addBotPath="add" />
        <Box sx={{ pt: 3 }}>
          <BotListResult
            bots={cheggBots}
            startBot={startCheggBot}
            stopBot={stopCheggBot}
            deleteBot={deleteCheggBot}
          />
        </Box>
      </Container>
    </Box>
  );
};

function Chegg() {
  return (
    <>
      <Helmet>
        <title>Chegg | StudenHub</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<CheggHome />} />
        <Route
          path="add"
          element={
            <CheggForm
              submitCallback={(data) =>
                addBot(`${cheggBaseUrl}/admin/bots`, data)
              }
            />
          }
        />
        <Route
          path="update/:botName"
          element={
            <CheggForm
              submitCallback={(botName, data) =>
                updateBot(`${cheggBaseUrl}/admin/bots/${botName}`, data)
              }
            />
          }
        />
      </Routes>
    </>
  );
}

export default Chegg;
