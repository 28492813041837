import { useState } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DateTime } from "luxon";
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Stack,
  Grid,
  IconButton,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Link as RouterLink } from "react-router-dom";
import { getInitials2 } from "../utils/getInitials";
import AlertDialog from "./AlertDialog";
import StyledBadge from "./StyledBadge";

const BotListResult = ({
  bots,
  startBot,
  stopBot,
  deleteBot,
  extras,
  ...rest
}) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [alertOpen, setAlertOpen] = useState(false);
  const [botName, setBotName] = useState("");
  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Bot Name</TableCell>
                <TableCell sx={{ width: "300px" }}>Account Email</TableCell>
                <TableCell>Expires At</TableCell>
                <TableCell>Extras</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bots.slice(limit * page, limit * page + limit).map((bot) => (
                <TableRow hover key={bot._id}>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        variant="dot"
                        color={
                          bot.extras.is_live
                            ? bot.extras.is_working
                              ? "success"
                              : "error"
                            : "secondary"
                        }
                      >
                        <Avatar src={bot.avatar_url || ""}>
                          {getInitials2(bot.bot_name)}
                        </Avatar>
                      </StyledBadge>
                      <Typography
                        sx={{ ml: 2 }}
                        color="textPrimary"
                        variant="body1"
                      >
                        {bot.bot_name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {bot.account_email ? bot.account_email : "N/A"}
                  </TableCell>
                  <TableCell>
                    {bot.expires_at
                      ? DateTime.fromISO(bot.expires_at).toLocaleString()
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {extras ? (
                      <Stack>
                        {extras.map((extraProperty) => (
                          <Typography key={bot.bot_name} variant="inherit">
                            {extraProperty.label}: {bot[extraProperty.key]}
                          </Typography>
                        ))}
                      </Stack>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item>
                        <IconButton
                          onClick={() => startBot(bot.bot_name)}
                          disabled={bot.extras.is_live ? true : false}
                          color="primary"
                        >
                          <PlayArrowIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          disabled={bot.extras.is_live ? false : true}
                          onClick={() => stopBot(bot.bot_name)}
                          color="primary"
                        >
                          <StopIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          component={RouterLink}
                          to={`update/${bot.bot_name}`}
                          disabled={bot.extras.is_live ? true : false}
                          color="primary"
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          disabled={bot.extras.is_live ? true : false}
                          onClick={() => {
                            setBotName(bot.bot_name);
                            setAlertOpen(true);
                          }}
                          color="primary"
                        >
                          <DeleteForeverOutlinedIcon />
                        </IconButton>
                        <AlertDialog
                          open={alertOpen}
                          setOpen={setAlertOpen}
                          onConfirm={() => deleteBot(botName)}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton
                          disabled={bot.extras.is_live ? false : true}
                          color="primary"
                        >
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={bots.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

BotListResult.propTypes = {
  bots: PropTypes.array.isRequired,
};

export default BotListResult;
